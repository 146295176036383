@import '../../../../styles/media-mixins';
@import '../../../../styles/fonts';

.rightBackground {
    position: absolute;
    z-index: -1;
    left: calc(100% - 56px);
    top: -157px;
    width: 489.69px;
    height: 520.5px;

    @media (min-width: $tabletMD) {
        left: calc(100% - 180px);
        top: -185px;
    }
    @media (min-width: $desktopMD) {
        left: calc(100% - 256px);
        top: -188.5px;
    }
    @media (min-width: $desktopXXL) {
        left: calc(100% - (456px - (100vw - 1184px) / 2));
        top: -188.5px;
    }

    img {
        width: 100%;
        height: auto;
    }
}

.firstSection__container {
    position: relative;
    @include flexbox();
    @include flex-wrap(wrap);
    margin-bottom: 91px;

    @media (min-width: $tabletMD) {
        margin-bottom: 211px; // 140 between sections + 120 of try uses image + 20 of try uses image top spaces (added as margin-top in the button) - 69px.
        @include flex-wrap(nowrap);
    }
    @media (min-width: $desktopMD) {
        margin-bottom: 91px;
    }
    @media (min-width: $desktopXXL) {
        margin-bottom: 82px;
        gap: 44px;
    }

    .leftColumn {
        @include flex(0, 0, 100%);
        margin-bottom: 40px;

        @media (min-width: $tabletMD) {
            @include flex(0, 0 , 50%);
            margin-bottom: 0;
        }
        @media (min-width: $desktopXXL) {
            @include flex(0, 0 , calc(50% - 20px));
            padding-left: 20px;
        }

        & > h1 {
            font-family: var(--custom);
            font-size: 40px;
            font-weight: 700;
            line-height: 48px;
            @media (min-width: $tabletMD) {
                font-size: 48px;
                line-height: 56px
            }
            @media (min-width: $desktopMD) {
                font-size: 56px;
                line-height: 64px;
            }
        }

        p {
            font-family: var(--base);
            font-size: 18px;
            font-weight: 400;
            line-height: 24px;
            @media (min-width: $tabletMD) {
                font-size: 20px;
                line-height: 28px;
                max-width: 500px;
            }
        }

        button {
            margin-top: 60px;
        }
    }

    .rightColumn {
        @include flex(0, 0, 100%);

        @media (min-width: $tabletMD) {
            margin-left: 10px;
            @include flexbox();
            @include flex(0, 0, calc(50% - 20px));
            @include flex-direction(column);
            @include justify-content(center);

        }
        @media (min-width: $desktopXXL) {
            margin-left: 0;
        }

        .mobileCredentialsImage__container {
            @include min-width($desktopXXL) {
                margin-top: -15px;
                height: calc(100% + 15px);
            }

            img {
                width: 100%;
                height: auto;
                @media (min-width: $tabletMD) {
                    max-width: 617px;
                    position: relative;
                    top: 0;
                    right: 0;
                }
                @media (min-width: $desktopXXL) {
                    width: auto;
                    height: 100%;
                }
            }
        }

        .tryDemoUseCasesImage__container {
            margin-top: 40px;
            @include flexbox();
            @include justify-content(center);

            @include min-width($tabletMD) {
                display: block;
                margin-top: 0;
                height: 0;
                margin-left: -10px;
            }
            @include min-width($desktopMD) {
                height: auto;
            }
            @media (min-width: $desktopXXL) {
                margin-top: -5px;
                height: 0;
                margin-left: -44px;
            }

            button {
                background-color: transparent;
                border: none;
                outline: none;
                cursor: pointer;
                &:focus-visible {
                    outline: none;
                }
                @media (min-width: $tabletMD) {
                    height: 0;
                    margin: 72px 0 0;
                }
                @media (min-width: $desktopMD) {
                    height: 0;
                    margin: 40px 0 0;
                }
                @media (min-width: $desktopXXL) {
                    margin: 0;
                }
            }

            img {
                width: 100%;
                height: auto;
                width: 120px;
                height: 120px;
                @media (min-width: $tabletMD) {
                    position: relative;
                    top: 0;
                       left: -6px;
                    height: auto;
                }
                @media (min-width: $desktopSM) {
                    max-width: 120px;
                }
                @media (min-width: $desktopXXL) {
                    top: -120px;
                    left: -6px;
                }
            }
        }
    }
}
@import "../../../../styles/colors.scss";
@import "../../../../styles/media-mixins.scss";

.backButton {
    @include flexbox();
    @include justify-content(center);
    @include align-items(center);
    cursor: pointer;
    background-color: transparent;
    border: none;
    padding: 0;
    &:hover {
        background-color: transparent;
    }
    & > svg {
        path {
            stroke:  var(--neutral1000);
        }
        &:hover {
            path {
                stroke: var(--neutral700);
            }
        }
    }
    & > span {
        color:  var(--neutral1000);
        &:hover {
            color: var(--neutral700);
        }
    }
}

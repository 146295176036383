// Colors 
.neutral100 {
    color: var(--neutral100)
}

.neutral400 {
    color: var(--neutral400)
}
.neutral500 {
    color: var(--neutral500)
}

.neutral600 {
    color: var(--neutral600)
}

.neutral700 {
    color: var(--neutral700)
}

.neutral800 {
    color: var(--neutral800)
}

.neutral1000 {
    color: var(--neutral1000)
}

.neutral100 {
    color: var(--neutral100)
}

.primary700 {
    color: var(--primary700)
}

.alert300 {
    color: var(--alert300)
}

.success300 {
    color: var(--success300)
}

@import "../../../../styles/media-mixins";
@import "../../../../styles/colors";

.useCaseCard {
    padding: 20px;
    height: calc(100% - 40px);
    border-radius: 8px;
    background-color: var(--neutral200);
    box-shadow: var(--boxShadowCard);
    @include flexbox();
    @include flex-direction(column);

    & > .imageContainer {
        width: 100%;
        overflow: hidden;
        aspect-ratio: 1 / 1;

        @include min-width($mobileXL) {
        width: 100%;
        }

        & > img {
            width: 100%;
            object-fit: cover;
            object-position: center center;
        }
    }

    & > .tagsContainer {
        margin-top: 20px;
        gap: 8px;
        margin-bottom: 32px;
        @include flexbox();
        @include flex-direction(row);

        & > .tag {
            background-color: var(--neutral100);
            border-radius: 8px;
            padding: 12px;
            width: calc(50% - 4px);
            height: auto;
            @include flexbox();
            @include flex-direction(column);

            @include min-width($tabletMD) {
                min-height: calc(77px - 24px);
            }
            @include min-width(809px) {
                min-height: 0;
            }
            @include min-width($desktopMD) {
                min-height: calc(77px - 24px);
            }
            @include min-width(1320px) {
                min-height: 0;
            }
        }

        // For spanish translations
        & > .largeTag {
            @include min-width($tabletMD) {
                min-height: calc(77px - 24px);
            }
           @include min-width(970px) {
                min-height: 0;
            }
            @include min-width($desktopMD) {
                min-height: calc(92px - 24px);
            }
            @include min-width(1189px) {
                min-height: calc(77px - 24px);
            }
        }
    }

    & > .button {
        width: fit-content;
    }
}

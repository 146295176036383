@import "../../../styles/media-mixins";
@import "../../../styles/colors";

.footer {
  margin: 0 auto;
  padding: 32px 16px;
  border-top: 1px solid var(--neutral400);
  @include min-width($tabletMD) {
    padding: 32px 24px;
  }
  @include min-width($desktopMD) {
    padding: 32px 164px;
  }
  .mainSectionFooter {
    @include flexbox();
    @include flex-wrap(wrap);
    @include justify-content(center);
    @include align-items(center);
    @include flex-direction(column);
    gap: 24px;
    width: 100%;
    @include min-width(630px) {
      @include justify-content(space-between);
      @include flex-wrap(nowrap);
      @include flex-direction(row);
    }
    @include min-width($desktopXXL) { 
      max-width: 1184px;
    }
    .policies {
      ul {
        @include flexbox();
        @include flex-wrap(wrap);
        @include justify-content(center);
        gap: 20px;
        padding: 0;
        margin: 0;
        @include min-width(1214px) {
          @include justify-content(space-between);
        }
        li {
          list-style: none;
          height: 16px;
          a {
            display: inline-block;
            color: var(--neutral1000)
          }
        }
      }
    }

    .rightsReserved {
      text-align: center;
      color: var(--neutral1000);

      @include min-width(1214px) {
        text-align: right;
      }
    }
  }
}
@import '../../../styles/media-mixins';

.home {
    margin: 69px auto 100px;

    .leftBackground {
        position: absolute;
        left: 0;
        top: 0;
        z-index: -1;
        img {
            max-height: 662px;
        }
    }

    & > .secondSectionContainer {
        position: relative;
        margin-bottom: 100px;
        @include flexbox();
        @include flex-direction(column);
        @include align-items(center);
    }
}
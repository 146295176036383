@import "../../../styles/media-mixins";
@import "../../../styles/colors";


.downloadAppContent__container{
  margin-top: 40px;
  gap: 100px;
  @include flexbox();
  @include flex-wrap(wrap);
  @include justify-content(center);
  @include align-items(flex-start);
  @include flex-direction(column);

  @include min-width($tabletMD) {
    gap: 60px;
  }
  @include min-width($desktopMD) {
    @include justify-content(space-between);
    @include flex-wrap(nowrap);
    @include flex-direction(row);
    @include justify-content(flex-start);
  }
  @include min-width($desktopXXL) {
    margin-top: 0;
    @include justify-content(space-between);
  }

  .downloadAppContent__descriptionTexts {
    position: relative;
    z-index: 1;
    @include min-width($tabletXL) {
      @include flex(0, 0, calc(50% - 40px));
    }
    @include min-width($desktopXXL) {
      margin-top: 80px;
    }

    h2 {
      font-family: var(--custom);
      font-size: 40px;
      line-height: 56px;
      font-weight: 400;
      margin-bottom: 24px;
      @include min-width($desktopXXL) {
        font-size: 48px;
        line-height: 64px;
      }

      & > br {
        @include min-width($tabletMD) {
          display: none;
        }
        @include min-width($desktopXXL) {
          display: none;
        }
      }
    }

    & > .paragraphs {
      margin-bottom: 24px;
      @include min-width($desktopMD) {
          margin-bottom: 32px;
      }

      & > p {
        font-family: var(--base);
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        @include min-width($desktopMD) {
          margin-bottom: 32px;
        }
        @include min-width($desktopXXL) {
          font-size: 20px;
          line-height: 28px; 
        }
      }
    }

    .downloadAppContent__buttons {
      @include flexbox();
      @include flex-wrap(wrap);
      gap: 20px;
      margin-bottom: 40px;
      @include min-width(600px) {
        @include flex-direction(row);
        @include align-items(center);
      }
      @include min-width($desktopMD) {
        margin-bottom: 60px;
      }

      a { 
        height: 50px;
        border-radius: 8px;
        &:hover {
          box-shadow: 0px 0px 4px 2px var(--neutral700);
        }
        & > img {
          height: 100%;
        }
      } 
    }
  }

  .downloadAppContent__rightSide {
    position: relative;
    @include min-width($tabletMD) {
      position: relative;
      width: 100%;
      margin: 0;
    }

    .downloadAppContent__QRContainer {
      z-index: 1;
      top: -18px;
      left: 0px;
      position: relative;
      width: calc(100vw - 32px);
      @include flexbox();
      @include flex-direction(column);
      @include justify-content(center);
      @include align-items(center);
      display: flex;
      justify-content: flex-start;
      padding-left: 0;

      @include min-width($tabletMD) {
        top: 0;
        left: 0;
        bottom: 0;
        width: 100%;
        display: flex;
        justify-content: center;
        justify-content: center;
        padding: 124px 0 206px;
        padding-left: 0;
      }

      @include min-width($desktopMD) {
        display: flex;
        justify-content: center;
        justify-content: center;
        padding-left: 0;
      }

      @include min-width($desktopXXL) {
        display: flex;
        justify-content: center;
        justify-content: center;
        padding: 183px 0 286px;
        padding-left: 0;
      }

      img {
        max-width: 346px;
        height: auto;
        margin-bottom: 6px;
        @include min-width($tabletMD) {
          max-width: 346px;
        }
      }

      a {
        font-size: 14.45px;
        font-family: var(--base);
        line-height: 17.841px;
        &:hover {
          color: var(--neutral700)
        }
      }
    }

    .downloadAppContent__imageContainer {
      height: 0;
      @include min-width($tabletMD) {
        height: auto;
        position: absolute;
        width: 100%;
        top: 0;
        margin-top: 0;
        display: flex;
        justify-content: center;
      }
 
      div:first-child {
        position: relative;
        top: -314px;
        @include min-width($tabletMD) {
          position: absolute;
          top: 0;
        }

        img {
          max-width: 235px;
          height: auto;
          @include min-width($tabletMD) {
            max-width: 381px;
          }
          @include min-width($desktopMD) {
            max-width: 378px;
          }
           @include min-width($desktopXXL) {
            max-width: 475px;
          }
        }
      }
    }

    .downloadAppContent__logoContainer {
      background-image: url("/assets/images/logo-use-cases-full-bg.png");
      z-index: -1;
      position: absolute;
      right: -16px;
      z-index: -1;
      width: 495.199px;
      height: 465.727px;
      left: calc(100vw - 225px);
      background-repeat: no-repeat;
      background-position: top left;
      background-size: contain;
      top: calc(100% - 362px);

      @include min-height(1209px) {
        background-image: none;
      }
      @include min-width($tabletMD) {
        background-image: url("/assets/images/logo-use-cases-full-bg.png");
        top: -25px;
        right: -24px;
        width: 801.754px;
        height: 754.038px;
        left: calc(100% - 340px);
      }
      @include min-width($desktopMD) {
        background-image: none;
      }
    }
  }
}

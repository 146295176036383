
@import "../../../styles/media-mixins";
@import "../../../styles/colors";


.container {
  width: 314px;
  height: 165px;
  border-radius: 8px;
  padding: 12px;
  margin-left: -12px;
  margin-top: 10px;
  background-color: var(--neutral1000);
  box-shadow: 2px 1px 6px 2px rgba(0, 0, 0, 0.0784313725);
  @include flexbox();
  @include flex-direction(column);
  @include justify-content(space-between);
  @include min-width($tabletMD) {
    margin-left: 0
  }

  &>.header {
    @include flexbox();
    @include justify-content(space-between);
    margin-bottom: 24px;

    &>div {
      @include flexbox();
      align-items: center;
      &>div {
        margin-left: 14px;
        @include flexbox();
        @include flex-direction(column);
      }

    }
  }
  .mainData {
    margin-bottom: 24px;
    color: var(--primary700)
  }
  .datesContainer {
    @include flexbox();
    @include justify-content(space-between);
  }
}

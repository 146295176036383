@import "../../../../styles/_colors.scss";
@import "../../../../styles/media-mixins.scss";

.secondSection {
  position: relative;
  width: 100%;
  margin: 69px auto 0px;
  @include flexbox();
  @include flex-direction(column);
  @include flex-wrap(wrap);


  @include min-width($desktopMD) {
    @include justify-content(center);
  }

  @include min-width($desktopXXL) {
    margin: 80px auto 0px;
    @include justify-content(center);
  }

  .categoriesContainer {
    width: 100%;
    max-width: 100%;
    overflow: scroll;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    margin: 60px auto;
    gap: 32px;
    @include flexbox();
    @include align-items(center);
  }

  .categoriesContainer::-webkit-scrollbar {
    display: none; /* Opera and Chrome */
  }

  .categorySelectedOption {
    border: 1px solid var(--primary1000);
    padding: 8px;
    border-radius: 8px;
    color: var(--primary700);
    text-align: center;
    white-space: nowrap;
    cursor: none;
  }

  .categoryOption {
    white-space: nowrap;
    cursor: pointer;
    @include flexbox();

    &:hover {
      color: var(--primary700);
    }
  }
}


.secondSection__useCasesContainer {
  @include flexbox();
  @include flex-wrap(wrap);
  @include justify-content(space-between);
  @include align-items(space-between);
  width: 100%;
  gap: 40px;

  @include min-width($tabletMD) {
    gap: 40px 24px;
  }
  @include min-width($desktopMD) {
    @include justify-content(flex-start);
  }
  @include min-width($desktopXXL) {
    gap: 40px;
  }

  & > div {
    @include flex(1, 0, 100%);
    @include min-width($tabletMD) {
      @include flex(1, 0, calc(50% - 12px));
    }
    @include min-width($desktopMD) {
      @include flex(1, 0, calc(calc(100%/3) - calc(48px / 3)));
      max-width: calc(calc(100%/3) - calc(48px / 3));
    }
        @include min-width($desktopXXL) {
      @include flex(1, 0, calc(calc(100%/3) - calc(80px / 3)));
    max-width: calc(calc(100%/3) - calc(80px / 3));
    }
  }
}

@import "../../../../styles/media-mixins";

.gaAlertNotification {
  position: fixed;
  z-index: 999999;
  width: calc(100% - 32px);
  max-width: 1184px;
  margin: 0px 16px;
  gap: 32px;
  bottom: 34px;

  @include min-width($tabletMD) {
    margin: 0 24px;
    max-width: calc(100% - 48px);
  }

  @include min-width($desktopMD) {
    margin: 0 32px;
    max-width: calc(100% - 64px);
  }
}

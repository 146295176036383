@import "../../../../styles/_colors.scss";

.button {
    background-color: transparent;
    color: var(--neutral1000);
    padding: 12px 24px;
    height: auto;
    min-height: 48px;
    border-radius: 8px;
    cursor: pointer;
    border: 1px solid var(--neutral1000);
    &:hover {
        background-color: var(--neutral300);
    }
    &:focus {
        box-shadow: inset 0px 0px 0px 2px var(--neutral900);
    }
}

.outlinedButton {
    background-color: transparent;
    border: 1px solid var(--neutral100);
    color: var(--neutral100);
    padding: 12px 24px;
    height: 48px;
    border-radius: 8px;
    cursor: pointer;

     &:hover {
        background-color:rgba(255, 255, 255, 0.2);
    }
    &:focus {
        box-shadow: inset 0px 0px 0px 2px var(--neutral900);
    }
}
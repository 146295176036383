@import '../../../../styles/media-mixins';

.multiSector {
    width: calc(100% - 40px);
    @include min-width($mobileXL) {
        width: calc(100% - 80px);
    }
    @include min-width($desktopXL) {
        width: 100%;
    }
}

@import 'styles/reset';
@import 'styles/fonts';
@import 'styles/spacing';
@import 'styles/media-mixins';

#main {
  height: 100%;
  width: 100%;
}

#body.dark {
  // --------------------------------------------------
  // Color variables
  // --------------------------------------------------

  // Neutral
  --neutral100: #1E1E20;
  --neutral200: #39393C;
  --neutral300: #545458;
  --neutral400: #707074;
  --neutral500: #8C8C90;
  --neutral600: #A9A9AC;
  --neutral700: #C6C6C8;
  --neutral800: #E4E4E4;
  --neutral900: #F2F2F2;
  --neutral1000: #FFFFFF;

  // Primary
  --primary100: #0C0B33;
  --primary200: #1B1A5F;
  --primary300: #2F2D8B;
  --primary400: #4745B7;
  --primary500: #6361C7;
  --primary600: #8281D7;
  --primary700: #A5A4E8;
  --primary800: #CCCBF8;
  --primary900: #DEDEFB;
  --primary1000: #F2F2FF;

  // Secondary
  --secondary100: #061E1F;
  --secondary200: #083D3E;
  --secondary300: #095C5E;
  --secondary400: #067A7E;
  --secondary500: #00999E;
  --secondary600: #25B2B7;
  --secondary700: #54CCD0;
  --secondary800: #8EE6E9;
  --secondary900: #BBF2F4;
  --secondary1000: #E8FEFF;

  // Tertiary
  --tertiary100: #360C18;
  --tertiary200: #5F152B;
  --tertiary300: #891E3E;
  --tertiary400: #B22851;
  --tertiary500: #DC3164;
  --tertiary600: #E85A84;
  --tertiary700: #F486A7;
  --tertiary800: #FFB6CD;
  --tertiary900: #FFD4E1;
  --tertiary1000: #FFF2F6;

  // Success
  --success100: #04380E;
  --success200: #186927;
  --success300: #3D9A4E;
  --success400: #80CA8D;
  --success500: #D9FADF;

  // Alert
  --alert100: #3D0608;
  --alert200: #8D1A1E;
  --alert300: #DC3D43;
  --alert400: #EE888C;
  --alert500: #FFDEDF;

  // Warning
  --warning100: #7A680B;
  --warning200: #B99B08;
  --warning300: #F7CE00;
  --warning400: #FBE263;
  --warning500: #FFF6CA;

  // Info
  --info100: #062542;
  --info200: #07549A;
  --info300: #0081F1;
  --info400: #6FB8F8;
  --info500: #E4F3FF;

  // Skeletons
  --skeletonLightBG: #e0e0e0;
  --skeletonDarkBG: #c6c6c6d1;

  // Grey
  --grey600: #757575;

  // Box Shadows
  --boxShadowLigh: 0px 0px 14.253px 0px rgba(0, 0, 0, 0.06);
  --boxShadowCard: 0px 0px 12px 0px #00000014;

  // --------------------------------------------------
  // Font variables
  // --------------------------------------------------
  --base: "Poppins";
  --custom: "Ubuntu";
  --simple: "Work Sans";
  --default: "Helvetica Neue"
}

#body.light {
  // --------------------------------------------------
  // Color variables
  // --------------------------------------------------

  // Neutral
  --neutral100: #ffffff;
  --neutral200: #f2f2f2;
  --neutral300: #e4e4e4;
  --neutral400: #c6c6c8;
  --neutral500: #a9a9ac;
  --neutral600: #8c8c90;
  --neutral700: #707074;
  --neutral800: #545458;
  --neutral900: #39393c;
  --neutral1000: #1e1e20;

  // Primary
  --primary100: #f2f2ff;
  --primary200: #DEDEFB;
  --primary300: #CCCBF8;
  --primary400: #A5A4E8;
  --primary500: #8281D7;
  --primary600: #6361C7;
  --primary700: #4745B7;
  --primary800: #2F2D8B;
  --primary900: #1B1A5F;
  --primary1000: #0C0B33;

  // Secondary
  --secondary100: #E8FEFF;
  --secondary200: #BBF2F4;
  --secondary300: #8EE6E9;
  --secondary400: #54CCD0;
  --secondary500: #25B2B7;
  --secondary600: #00999E;
  --secondary700: #067A7E;
  --secondary800: #095C5E;
  --secondary900: #083D3E;
  --secondary1000: #061E1F;

  // Tertiary
  --tertiary100: #FFF2F6;
  --tertiary200: #FFD4E1;
  --tertiary300: #FFB6CD;
  --tertiary400: #F486A7;
  --tertiary500: #E85A84;
  --tertiary600: #DC3164;
  --tertiary700: #B22851;
  --tertiary800: #891E3E;
  --tertiary900: #5F152B;
  --tertiary1000: #360C18;

  // Success
  --success100: #D9FADF;
  --success200: #80CA8D;
  --success300: #3D9A4E;
  --success400: #186927;
  --success500: #04380E;

  // Alert
  --alert100: #FFDEDF;
  --alert200: #EE888C;
  --alert300: #DC3D43;
  --alert400: #8D1A1E;
  --alert500: #3D0608;

  // Warning
  --warning100: #FFF6CA;
  --warning200: #FBE263;
  --warning300: #F7CE00;
  --warning400: #B99B08;
  --warning500: #7A680B;

  // Info
  --info100: #E4F3FF;
  --info200: #6FB8F8;
  --info300: #0081F1;
  --info400: #07549A;
  --info500: #062542;

  // Skeletons
  --skeletonLightBG: #e0e0e0;
  --skeletonDarkBG: #c6c6c6d1;

  // Grey
  --grey600: #757575;

  // Box Shadows
  --boxShadowLigh: 0px 0px 14.253px 0px rgba(0, 0, 0, 0.06);
  --boxShadowCard: 0px 0px 12px 0px #00000014;

  // --------------------------------------------------
  // Font variables
  // --------------------------------------------------
  --base: "Poppins";
  --custom: "Ubuntu";
  --simple: "Work Sans";
  --default: "Helvetica Neue"
}

#main {
  &::before {
    content: '';
    position: fixed;
    z-index: -999;
    min-height: 100%;
    width: calc(100% - 32px);
    max-width: 1184px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background:
      repeating-linear-gradient(var(--neutral200),
        var(--neutral200) 3px,
        transparent 3px,
        transparent 6px) 0%,
      repeating-linear-gradient(var(--neutral200),
        var(--neutral200) 3px,
        transparent 3px,
        transparent 6px)25%,
      repeating-linear-gradient(var(--neutral200),
        var(--neutral200) 3px,
        transparent 3px,
        transparent 6px)50%,
      repeating-linear-gradient(var(--neutral200),
        var(--neutral200) 3px,
        transparent 3px,
        transparent 6px)75%,
      repeating-linear-gradient(var(--neutral200),
        var(--neutral200) 3px,
        transparent 3px,
        transparent 6px)100%;
    background-size: 1px 100%;
    background-repeat: repeat-y;
    @include min-width($tabletMD) {
      width: calc(100% - 48px);
    }
    @include min-width($desktopMD) {
      width: calc(100% - 168px);
    }
    @include min-width($desktopXXL) {
      width: calc(100% - 328px);
    }
    @include min-width($desktopXXXL) {
      width: calc(100% - 168px);
    }
  }
}

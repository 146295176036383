@import "../../../../styles/colors.scss";
@import "../../../../styles/media-mixins.scss";

$items: 8;
$velocity: 1.2;
$colorStart: var(--primary700);
$colorEnd: var(--primary300);

// MD Sizes
$itemWidthMD: 15.68;
$itemHeightMD: 5.5;
$radiusMD: 20;


// Trigonometry helpers
$pi: 3.14159265359;
$_precision: 10;

@function pow($base, $exp) {
  $value: $base;
  @if $exp > 1 {
    @for $i from 2 through $exp {
      $value: $value * $base;
    }
  }
  @if $exp < 1{
    @for $i from 0 through -$exp {
      $value: $value / $base;
    }
  }
  @return $value;
}

@function fact($num) {
  $fact: 1;
  @if $num > 0{
    @for $i from 1 through $num {
      $fact: $fact * $i;
    }
  }
  @return $fact;
}

@function _to_unitless_rad($angle) {
  @if unit($angle) == "deg" {
    $angle: $angle / 180deg * $pi;
  }
  @if unit($angle) == "rad" {
    $angle: $angle / 1rad;
  }
  @return $angle;
}

@function sin($angle){
  $a: _to_unitless_rad($angle);
  $sin: $a;
  @for $n from 1 through $_precision {
    $sin: $sin + (pow(-1, $n) / fact(2 * $n + 1) ) * pow($a, (2 * $n + 1));
  }
  @return $sin;
}

@function cos($angle){
  $a: _to_unitless_rad($angle);
  $cos: 1;
  @for $n from 1 through $_precision {
    $cos: $cos + ( pow(-1,$n) / fact(2*$n) ) * pow($a,2*$n);
  }
  @return $cos;
}

// Styles
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
}

.loader {
  position: relative;
  margin-left: 0 !important;

  &__item {
    position: absolute;
    width: $itemWidthMD + px;
    height: $itemHeightMD + px;
    background: transparent;
    border-radius: $itemHeightMD / 2 + px;
  }
  

  @for $i from 1 through $items {
    &__item__#{$i} {
      $deg: 360deg / $items * $i;
      $offsetX: (cos(360deg / $items * $i) * $radiusMD) - ($itemWidthMD / 2);
      $offsetY: (sin(360deg / $items * $i) * $radiusMD) - ($itemHeightMD / 2);

      transform: translate($offsetX + px, $offsetY + px) rotate($deg);
      -webkit-transform: translate($offsetX + px, $offsetY + px) rotate($deg);
      -moz-transform: translate($offsetX + px, $offsetY + px) rotate($deg);
      -o-transform: translate($offsetX + px, $offsetY + px) rotate($deg);

      animation: blink #{1 / $velocity}s linear #{$i * (1 / $velocity) / $items}s infinite;
      -webkit-animation: blink #{1 / $velocity}s linear #{$i * (1 / $velocity) / $items}s infinite;
      -moz-animation: blink #{1 / $velocity}s linear #{$i * (1 / $velocity) / $items}s infinite;
      -o-animation: blink #{1 / $velocity}s linear #{$i * (1 / $velocity) / $items}s infinite;
    }
  }
  
  &__item__8 {
    max-width: $itemWidthMD + px;
    height: $itemHeightMD + px;
  }
} 

/// Animations
@keyframes blink {
  0% {
    background: $colorStart;
  }
  100% {
    background: $colorEnd;
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    background: rotate(360deg);
  }
}
@import "../../../styles/media-mixins";

.generalContainer {
        height: 100%;
    @include min-height(1209px) {
        background-image: url("/assets/images/logo-use-cases-bg.png");
        background-repeat: no-repeat;
        background-position: bottom right;
        background-size: 225px 408px;
    }
    @include min-width($tabletMD) {
        background-image: none;
    }
    @include min-width($desktopMD) {
        background-image: url("/assets/images/logo-use-cases-bg.png");
        background-repeat: no-repeat;
        background-position: top right;
        background-size:contain;
        min-height: 869px;
    }
    @include min-width($desktopXXL) {
        min-height: 1000px;
    }
}

.downloadWalletContainer {
    min-height: calc(100vh - 112px);
    margin-top: 24px;
    box-sizing: border-box;
    @include min-width($tabletMD) {
        min-height: calc(100vh - 112px);
        height: fit-content;
    }
     @include min-width($desktopXXL) {
        min-height: calc(100vh);
        margin: 80px auto 0;
    }
}




@import "./_reset";
@import "./_media-mixins";
@import "./_colors";
@import "./_fonts";
@import "./_spacing";


:global {

    // Width
    .width100 {
        width: 100%;
    }

    .gridWidth {
        width: calc(100% - 32px);
        margin-left: auto;
        margin-right: auto;
        @include min-width($tabletMD) {
            width: calc(100% - 48px);
        }
        @include min-width($desktopMD) {
            width: calc(100% - 168px);
        }
        @include min-width($desktopXXL) {
            width: calc(100% - 328px);
        }
    }

    .maxWidth {
        max-width: 1184px;
    }

    .stepMaxWidth {
        max-width: 1184px;
        @include min-width($desktopXXL) {
            max-width: 1060px;
        }
        @include min-width(1513px) {
            max-width: 1184px;
        }
        @include min-width($desktopXXXL) {
            max-width: 1512px;
        }
    }

    // Custom others
    .backgroundImageContainer {
        background-image: url("/assets/images/logo-use-cases-bg.png");
        background-repeat: no-repeat;
        background-position: top right;
        background-size: contain;
    }

    .useCaseBackContainer {
        margin: 24px auto 0;
        @include min-width($desktopXXL) {
            margin: 80px auto 0;
        }
    }
}

@import '../../styles/media-mixins';

.flowFinishContainer {
    padding-bottom: 40px;
    margin-top: 24px;
    min-height: calc(100vh - calc(88px + 24px + 40px));
    background-position: bottom -50px right -275px;
    @include min-width($tabletMD) {
        padding-bottom: 48.05px;
        min-height: calc(100vh - calc(88px + 24px + 48.05px));
        background-position: bottom right -250px;
    }
    @include min-width($desktopMD) {
        padding-bottom: 120px;
        margin-top: 64.02px;
        min-height: calc(100vh - calc(88px + 64.02px + 120px));
        background-position: bottom -110px right -45px;
    }
    @include min-width($desktopXXL) {
        padding-bottom: 145.5px;
        margin-top: 57.5px;
        min-height: calc(100vh - calc(88px + 57.5px + 145.5px));
        background-position: bottom right;
    }
    .flowFinish {
        width: calc(100% - 32px);
        @include min-width($tabletMD) {
            width: calc(100% - 48px);
        }
        @include min-width($desktopMD) {
            width: calc(100% - 168px);
        }
    }
}

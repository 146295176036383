
@import "../../../styles/media-mixins";
@import "../../../styles/colors";

.card {
    border-radius: 12px;
    padding: 31px;
    width: calc(314px - 62px);
    height: calc(314px - 62px);
    background-color: var(--neutral1000);
    @include flexbox();
    @include flex-direction(column);
    @include justify-content(center);
    @include align-items(center);
    .textBig {
        font-family: var(--custom);
        font-size: 26px;
        line-height: 31px;
        font-weight: 700;
        text-align: center;
        margin-top: 34.5px;
    }
    .textSmaller {
        font-family: var(--base);
        font-size: 18px;
        line-height: 21px;
        font-weight: 400;
        text-align: center;
    }

}

@import '../../../../styles/media-mixins';

.bgContainer {
    @include min-width($desktopMD) {
        background-size: 50%;
    }
    @include min-max-width-height($desktopMD, 1239.99px, $mobileXL, $tabletSM) {
        background-size: 50%;
    }
    @include min-max-width-height(1140px, 1440px, 0, 810px) {
        background-size: contain;
    }
    @include min-max-width-height(1240px, 1449.99px, $mobileXL, $tabletSM) {
        background-size: contain;
    }
    @include min-width(1450px) {
        background-size: contain;
    }
}
.mainContainerBg {
    @include min-width($desktopXXL) {
        background-size: 50%;
    }
}
.QrContainer {
    @include min-width($desktopXL) {
        left: 110px !important;
        top: 210px !important;
    }
    @include min-max-width-height($desktopXL, $desktopXXL, $mobileXL, $tabletSM) {
        left: 220px !important;
        top: 130px !important;
    }
    @include min-width($desktopXXL) {
        left: 240px !important;
        top: 149px !important;
    }
    @include min-width(1513px) {
        left: 130px !important;
        top: 200px !important;
    }
    @include min-width($desktopXXXL) {
        left: 210px !important;
        top: 110px !important;
    }
}
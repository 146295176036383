@import "../../../../styles/media-mixins";

$circleSize: 13px;
$circleBorderWidth: 2px;
$lineOffset: 0px;

.progressBar_container {
    width: 100%;
    margin: 0 auto;
    display: flex;
    @include order(3);

    @include min-width($tabletMD) {
        max-width: calc(100% - 548px);
        max-width: none;
        width: 100%;
    }
    @include min-width($desktopMD) {
        transform: translate(-50%, 0%);
        top: 18px;
        left: 50%;
        position: absolute;
        width: 50.78%;
        min-width: 520px;
        @include order(2)
    }
    @include min-width($desktopXXL) {
        min-width: 778px;
        width: 778px;
    }
}

.short_container {
    @include min-width($desktopMD) {
        top: 24.5px;
    }
    & > ul > li {
        width: 33%;

        & > .progress_text {
            max-width: 78px;
            @include min-width(633px) {
                max-width: none;
            }
            @include min-width($tabletMD) {
                max-width: none;
            }
            @include min-width($desktopXXL) {
                max-width: none;
            }
        }
    }
}

.progressbar {
    counter-reset: step;
    margin: auto;
    justify-content: space-between;
    width: 100%;
    display: flex;
    @include min-width(630px) {
        max-width: none;
    }
}

.stepContainer {
    display: flex;
    justify-content: space-between;
}

.progressbar  li {
    list-style-type: none;
    width: 25%;
    position: relative;
    float: left;
    font-size: 12px;
    @include flexbox();
    @include flex-direction(column);
    @include align-items(center);

    @include min-width($tabletMD) {
        font-size: 14px;
    }
    @include min-width($desktopMD) {
        font-size: 12px;
    }
    @include min-width($desktopXXL) {
        font-size: 14px;
    }
}

.progressbar  div.progress_text {
    margin: 4px auto 0;
    display: inline-block;
    text-align: center;
    line-height: 16px;
    max-width: 114px;
    @include min-width(634px) {
        max-width: none;
    }
    @include min-width($tabletMD) {
        max-width: none;
    }
    @include min-width($desktopMD) {
        max-width: 112px;
    }
    @include min-width(1188px) {
        max-width: none;
    }
}

.progressbar li:before {
    content: url("../../../../assets/images/default-circle.svg");
    counter-increment: step;
    height: 16px;
    border: none;
    background-color: transparent;
    margin: 0 auto;
}

.progressbar li.completed {
    font-weight: 400;
    &:before {
        content: url("../../../../assets/images/checked-circle.svg");
        border: none;
    }
}

.progressbar li.active:before {
    content: url("../../../../assets/images/active-circle.svg");
    border: none;
}


.progressbar li:after {
    content: "";
    position: absolute;
    width: calc(100% - 12px);
    height: 2px;
    background-color: var(--neutral400);
    top: 6.5px;
    left: calc(-50% + 6px);
    z-index: -1;
}

.progressbar  li.active:after, li.completed:after {
    background-color: var(--neutral1000);
}

.progressbar li.active:after {
    background-color: var(--neutral1000);
}

.progressbar li.uncompleted {
  font-weight: 400px;
}

.progressbar  li:first-child:after {
    content: none;
}

@import '../../../../styles/media-mixins';

.rightSideContainer {
    margin-top: 0;
    height: calc(100vh - 429px);
    @include min-width(390px) {
        height: calc(100vh - 540px);
    }
    @include min-width($mobileMD) {
        height: calc(100vh - 269px); //Calculation 172px height header, 25px height goBack, 24px marginTop, 8px gap & 40px marginTop
    }
    @include min-width(580px) {
        height: calc(100vh - 349px);
    }
    @include min-max-width-height($tabletSM, $desktopMD, 0, 740px) {
        height: 100vh;
    }
    @include min-width($desktopMD) {
        height: auto;
    }
}
@import '../../../styles/media-mixins';

.flowFinishElements {
    background-color: var(--neutral200);
    border-radius: 40px;
    padding: 40px;
    @include flexbox();
    @include flex-direction(column);
    @include justify-content(space-between);
    @include align-items(center);
    @include flex-wrap(wrap);
    gap: 40px;
    @include min-width($tabletMD) {
        padding: 80px;
    }
    @include min-width($desktopMD){
        @include flex-direction(row);
        @include flex-wrap(nowrap);
    }
    @include min-width($desktopXXL) {
        padding: 120px;
        gap: 92px;
    }
    .leftColumn {
        @include min-width($desktopMD) {
            @include flex(0, 0, calc(50% - 20px));
        }
        @include min-width($desktopXXL) {
            @include flex(0, 0, calc(52.544% - 46px));
        }
    }
    .rightColumn {
        @include min-width($desktopMD) {
            @include flex(0, 0, calc(50% - 20px));
            text-align: right;
        }
        @include min-width($desktopXXL) {
            @include flex(0, 0, calc(47.456% - 46px));
        }
    }
    h1 {
        font-family: var(--custom);
        font-size: 40px;
        font-style: normal;
        font-weight: 700;
        line-height: 48px;
        @include min-width($desktopXXL) {
            font-size: 56px;
            line-height: 64px;
        }
    }
    p { 
        font-family: var(--base);
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        margin-bottom: 40px;
        @include min-width($tabletMD) {
            font-size: 20px;
            line-height: 28px;
            margin-bottom: 52px;   
        }
    }
    img {
        max-width: 100%;
        height: auto;
    }
}

@import "../../../styles/colors";
@import "../../../styles/media-mixins";

.gatacaQR__container {
    background-color: var(--neutral1000);
    width: 313px;
    height: 313px;
    border-radius: 20px;
    box-shadow: var(--boxShadowLigh);
    @include flexbox();
    @include justify-content(center);
    @include align-items(center);
}

.loadingQR__issuance {
    width: calc(313px - 62px);
    height: calc(313px - 62px);
    border-radius: 20px;
    position: absolute;
    z-index: 9999;
}

.loadingQR__verification {
    width: 323px;
    min-width: calc(323px - 62px);
    height: calc(323px - 62px);
    border-radius: 12px;
}
@import "../../../styles/colors";
@import "../../../styles/media-mixins";

.header {
  @include flexbox();
  @include flex-wrap(wrap);
  @include justify-content(space-between);
  @include align-items(center);
  min-height: 40px;
  padding: 24px 16px;
  gap: 32px;
  @include min-width($tabletMD) {
    padding: 24px;
  }
  @include min-width($desktopMD) {
    padding: 24px 32px;
    height: 40px;
  }

  &__logo {
    height: 40px;
    img {
      max-width: 170px;
      height: auto;
    }
  }

  &__exitButton {
    height: 40px;
    @include flexbox();
    @include align-items(center);
    @include order(2);
    cursor: pointer;

    @include min-width($desktopMD) {
      @include order(3)
    }
  }
}


@import "../../../styles/media-mixins";
@import "../../../styles/colors";

.card {
    max-width: 318px;
    width: 100%;
    height: 40px;
    border-radius: 8px;
    padding: 12px;
    background-color: var(--neutral1000);
    @include flexbox();
    @include flex-direction(column);
    @include justify-content(space-between);
    margin-bottom: 24px;
    @include min-width($desktopMD) {
        margin-bottom: 32px
    }
    .elementsContainer {
        @include flexbox();
        @include flex-direction(row);
        @include justify-content(flex-start);
        @include flex-wrap(nowrap);
        gap: 12px;
        p {
            color: var(--neutral100);
        }
    }
}

@import "../../../styles/media-mixins";

.gaAlert {
  position: fixed;
  z-index: 999999;
  width: calc(100% - 32px);
  max-width: 1184px;
  margin: 0px 16px;
  gap: 32px;
  bottom: 34px;

  @include min-width($tabletMD) {
    margin: 0 24px;
    max-width: calc(100% - 48px);
  }

  @include min-width($desktopMD) {
    margin: 0 32px;
    max-width: calc(100% - 64px);
  }
}

.gaAlertPanel {
  padding: 12px;
  border-radius: 8px;

  .content {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 12px;

    & > .trailingIcon {
      height: 24px;
      width: 24px;
      @include flexbox();
      align-self: start;
    }

    .texts {
      .title {
        font-weight: bold;
        font-size: 1rem;
      }
    }

    .close {
      height: 24px;
      margin-left: auto;
      cursor: pointer;
    }
  }
}

.error {
  background-color: var(--alert100);
  border: 1px solid var(--alert200);

    & > .content > .close {
        & > .closeIcon:hover {
            & > path {
                fill: var(--alert300);
            }
        }
    }
}

.info {
  background-color: var(--info100);
  border: 1px solid var(--info200);

    & > .content > .close {
        & > .closeIcon:hover {
            & > path {
                fill: var(--info300);
            }
        }
    }
}

@import "../../../styles/media-mixins";
@import "../../../styles/colors";

  .backgroundImageContainer {
    @include max-width(1023px) {
      background-image: none !important;
    }
    @include min-width($desktopMD) {
      background-repeat: no-repeat;
      background-position: bottom right;
      height: fit-content;
      background-size: 65%;
      min-height: calc(100vh - 177px); //Calculation 88px height header, 25px height goBack & 40px marginTop
      margin-top: 40px;
    }
    @include min-max-width-height($desktopMD, 1239.99px, 0, $tabletMD) {
      background-size: 50%;
    }
    @include min-max-width-height(1135px, 1440px, 0, 800px) {
      background-size: contain;
    }
    @include min-max-width-height(1240px, 1379.99px, $mobileXL, $tabletSM) {
      background-size: contain;
    }
    @include min-width(1380px) {
      background-size: contain;
    }
    @include min-width($desktopXXL) {
      min-height: calc(100vh - 273px); //Calculation 88px height header,25px height goBack & 80px marginTop
      margin-top: 80px;
    }
  }

  .bgImageContainerColumn {
    background-repeat: no-repeat;
    background-position: bottom right;
    background-size: contain;
    height: 100vh;
    width: 100vw;
    margin-top: 40px;
    position: relative;
    @include min-width(580px) {
      height: calc(100vh - 349px); //Calculation 172px height header, 25px height goBack, 24px marginTop, 120px the smallest content & 8px gap
      margin-top: 0;
    }
    @include min-max-width-height($tabletSM, $desktopMD, 0, 740px) {
      height: 100vh;
    }
  }
  .stepsContent__mainContainer {
      background-repeat: no-repeat;
      background-position: bottom right;
      height: fit-content;
      background-size: contain;
      min-height: calc(100vh - 273px); //Calculation 88px height header, 25px height goBack & 24px marginTop
  }

  .stepsContent__container{
    margin: 40px auto 0;
    @include flexbox();
    @include flex-wrap(wrap);
    @include justify-content(center);
    @include align-items(flex-start);
    @include flex-direction(column);
    gap: calc(40px - 32px);
    @include min-width($desktopMD) {
      @include justify-content(space-between);
      @include flex-wrap(nowrap);
      @include flex-direction(row);
      margin: 0 auto;
      gap: 40px;
    }

    .stepsContent__descriptionTexts {
      position: relative;
      z-index: 1;
      @include min-width($desktopMD) {
        @include flex(0, 0, calc(50.43% - 20px));
        padding-bottom: 32px;
      }
      @include min-width($desktopXXL) {
        @include flex(0, 0, calc(41.006% - 20px));
      }
      .paragraphs {
        &.descriptionParagraphsQR {
          margin-bottom: 24px;
          @include min-width($desktopMD) {
            margin-bottom: 32px;
          }
        }
      }
      h2 {
        font-family: var(--custom);
        font-size: 40px;
        font-style: normal;
        font-weight: 700;
        line-height: 48px;
        margin-bottom: 24px;
        @include min-width($desktopMD) {
          font-size: 48px;
          line-height: 56px;
          margin-bottom: 32px;
        }
      }
      .normalText {
          font-family: var(--base);
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
          margin-bottom: 24px;
          @include min-width($desktopMD) {
            font-size: 20px;
            line-height: 28px;
            margin-bottom: 32px;
          }
      }
      .normalText__noMargin {
        font-family: var(--base);
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        @include min-width($desktopMD) {
          font-size: 20px;
          line-height: 28px;
        }
      }
      .staticAlert {
        margin-bottom: 24px;
        @include min-width($desktopMD) {
          margin-bottom: 32px;
        }
      }
      .button {
        padding-top: calc(40px - 24px);
        @include min-width($desktopMD) {
          padding-top: calc(40px - 32px)
        }
      }

      a {
        text-decoration: underline;
        font-family: var(--base);
        font-weight: 400;
        line-height: 28px;
      }

      .credentialComponent__container {
        @include flexbox();
        @include flex-direction(column);
        @include flex-wrap(wrap);
        @include min-width($mobileXL) {
          @include flex-wrap(nowrap);
          @include flex-direction(row);
          gap: 32px;
        }
        @include min-width($desktopMD) {
          @include flex-direction(column);
          @include flex-wrap(wrap);
          gap: 0;
        }
        @include min-width(1100px) {
          @include flex-wrap(nowrap);
          @include flex-direction(row);
          gap: 32px;
        }
      }
    }

    .stepsContent__rightSide {
      @include min-width($desktopMD) {
          @include flex(0, 0, calc(49.57% - 20px));
      }

      .stepsContent__QRContainer {
        @include flexbox();
        @include flex-direction(column);
        @include justify-content(center);
        @include align-items(center);
        @include min-width($tabletMD) {
          z-index: 1;
          position: absolute;
          left: 120px;
          top: calc(80px - 40px);
        }
        @include min-width($desktopMD) {
          left: 0;
          top: 40px;
        }
        @include min-width($desktopXXL) {
          top: 0;
        }
        .stepsContent__QRContainer__limitations {
          max-width: 314px;
          max-height: 314px;
          margin: 0 32px 0 0; 
          @include min-width($tabletMD) {
            margin: 0;
          }
        }
      }
    }
  }

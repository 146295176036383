// spacing
.margin-v {
    margin: 20px 0;
}

.padding-h {
    padding: 0 20px;
}

.padding20 {
    padding: 20px;
}

.padding-v {
    padding: 20px 0;
}

.padding-top {
    padding-top: 20px;
}

.padding-bottom {
    padding-bottom: 20px;
}

.margin-v {
    margin: 20px 0;
}

.margin-top-micro {
    margin-top: 5px;
}

.margin-top-min {
    margin-top: 10px;
}

.margin-top {
    margin-top: 20px;
}

.margin-top-m {
    margin-top: 30px;
}

.margin-top-big {
    margin-top: 40px;
}

.margin-bottom-m {
    margin-bottom: 30px;
}

.margin-bottom {
    margin-bottom: 20px;
}

.margin-bottom-min {
    margin-bottom: 10px;
}

.margin-left-auto {
    margin-left: auto;
}

.margin-right {
    margin-right: 20px;
}

.margin-right-min {
    margin-right: 10px;
}


.padding10 {
    padding: 10px;
}

.text-align-right {
    text-align: right;
}

.text-align-left {
    text-align: left;
}

// Fonts
.size-min {
    font-size: 14px;
}

.font-weight-bold {
    font-weight: bold;
}

// New spacing classes
// Horizontal & vertical:
.margin80 {
    margin: 80px;
}

.margin40 {
    margin: 40px;
}

.margin32 {
    margin: 32px;
}

.margin30 {
    margin: 30px;
}

.margin24 {
    margin: 24px;
}

.margin18 {
    margin: 18px;
}

.margin22 {
    margin: 22px;
}

.margin20 {
    margin: 20px;
}

.margin15 {
    margin: 15px;
}

.margin14 {
    margin: 14px;
}

.margin12 {
    margin: 12px;
}

.margin10 {
    margin: 10px;
}

.margin8 {
    margin: 8px;
}

.margin6 {
    margin: 6px;
}

.margin5 {
    margin: 5px;
}

.margin4 {
    margin: 4px;
}

.margin3 {
    margin: 3px;
}

.margin2 {
    margin: 2px;
}

.margin1 {
    margin: 1px;
}

.margin05 {
    margin: 0.5px;
}

.margin0 {
    margin: 0px;
}

.marginAuto {
    margin: auto
}


// Top
.marginTop80 {
    margin-top: 80px;
}

.marginTop40 {
    margin-top: 40px;
}

.marginTop32 {
    margin-top: 32px;
}

.marginTop30 {
    margin-top: 30px;
}

.marginTop24 {
    margin-top: 24px;
}

.marginTop22 {
    margin-top: 22px;
}

.marginTop20 {
    margin-top: 20px;
}

.marginTop18 {
    margin-top: 18px;
}

.marginTop15 {
    margin-top: 15px;
}

.marginTop14 {
    margin-top: 14px;
}

.marginTop12 {
    margin-top: 12px;
}

.marginTop10 {
    margin-top: 10px;
}

.marginTop8 {
    margin-top: 8px;
}

.marginTop6 {
    margin-top: 6px;
}

.marginTop5 {
    margin-top: 5px;
}

.marginTop4 {
    margin-top: 4px;
}

.marginTop3 {
    margin-top: 3px;
}

.marginTop2 {
    margin-top: 2px;
}

.marginTop1 {
    margin-top: 1px;
}

.marginTop05 {
    margin-top: 0.5px;
}

.marginTop0 {
    margin-top: 0px;
}

.marginTopAuto {
    margin-top: auto
}

// left
.marginLeft80 {
    margin-left: 80px;
}

.marginLeft40 {
    margin-left: 40px;
}

.marginLeft32 {
    margin-left: 32px;
}

.marginLeft30 {
    margin-left: 30px;
}

.marginLeft24 {
    margin-left: 24px;
}

.marginLeft22 {
    margin-left: 22px;
}

.marginLeft20 {
    margin-left: 20px;
}

.marginLeft18 {
    margin-left: 18px;
}

.marginLeft15 {
    margin-left: 15px;
}

.marginLeft14 {
    margin-left: 14px;
}

.marginLeft12 {
    margin-left: 12px;
}

.marginLeft10 {
    margin-left: 10px;
}

.marginLeft8 {
    margin-left: 8px;
}

.marginLeft6 {
    margin-left: 6px;
}

.marginLeft5 {
    margin-left: 5px;
}

.marginLeft4 {
    margin-left: 4px;
}

.marginLeft3 {
    margin-left: 3px;
}

.marginLeft2 {
    margin-left: 2px;
}

.marginLeft1 {
    margin-left: 1px;
}

.marginLeft05 {
    margin-left: 0.5px;
}

.marginLeft0 {
    margin-left: 0px;
}

.marginLeftAuto {
    margin-left: auto
}

// right
.marginRight80 {
    margin-right: 80px;
}

.marginRight40 {
    margin-right: 40px;
}

.marginRight32 {
    margin-right: 32px;
}

.marginRight30 {
    margin-right: 30px;
}

.marginRight24 {
    margin-right: 24px;
}

.marginRight22 {
    margin-right: 22px;
}

.marginRight20 {
    margin-right: 20px;
}

.marginRight18 {
    margin-right: 18px;
}

.marginRight15 {
    margin-right: 15px;
}

.marginRight14 {
    margin-right: 14px;
}

.marginRight12 {
    margin-right: 12px;
}

.marginRight10 {
    margin-right: 10px;
}

.marginRight8 {
    margin-right: 8px;
}

.marginRight6 {
    margin-right: 6px;
}

.marginRight5 {
    margin-right: 5px;
}

.marginRight4 {
    margin-right: 4px;
}

.marginRight3 {
    margin-right: 3px;
}

.marginRight2 {
    margin-right: 2px;
}

.marginRight1 {
    margin-right: 1px;
}

.marginRight05 {
    margin-right: 0.5px;
}

.marginRight0 {
    margin-right: 0px;
}

.marginRightAuto {
    margin-right: auto
}

// bottom
.marginBottom80 {
    margin-bottom: 80px;
}

.marginBottom40 {
    margin-bottom: 40px;
}

.marginBottom32 {
    margin-bottom: 32px;
}

.marginBottom30 {
    margin-bottom: 30px;
}

.marginBottom24 {
    margin-bottom: 24px;
}

.marginBottom22 {
    margin-bottom: 22px;
}

.marginBottom22 {
    margin-bottom: 22px;
}

.marginBottom20 {
    margin-bottom: 20px;
}

.marginBottom16 {
    margin-bottom: 16px;
}

.marginBottom15 {
    margin-bottom: 15px;
}

.marginBottom14 {
    margin-bottom: 14px;
}

.marginBottom12 {
    margin-bottom: 12px;
}

.marginBottom10 {
    margin-bottom: 10px;
}

.marginBottom8 {
    margin-bottom: 8px;
}

.marginBottom6 {
    margin-bottom: 6px;
}

.marginBottom5 {
    margin-bottom: 5px;
}

.marginBottom4 {
    margin-bottom: 4px;
}

.marginBottom3 {
    margin-bottom: 3px;
}

.marginBottom2 {
    margin-bottom: 2px;
}

.marginBottom1 {
    margin-bottom: 1px;
}

.marginBottom05 {
    margin-bottom: 0.5px;
}

.marginBottom0 {
    margin-bottom: 0px;
}

.marginBottomAuto {
    margin-bottom: auto
}

// Padding

// Horizontal & vertical:
.padding32 {
    padding: 32px;
}

.padding30 {
    padding: 30px;
}

.padding22 {
    padding: 22px;
}

.padding20 {
    padding: 20px;
}

.padding15 {
    padding: 15px;
}

.padding14 {
    padding: 14px;
}

.padding12 {
    padding: 12px;
}

.padding10 {
    padding: 10px;
}

.padding8 {
    padding: 8px;
}

.padding6 {
    padding: 6px;
}

.padding5 {
    padding: 5px;
}

.padding4 {
    padding: 4px;
}

.padding3 {
    padding: 3px;
}

.padding2 {
    padding: 2px;
}

.padding1 {
    padding: 1px;
}

.padding05 {
    padding: 0.5px;
}

.paddingTop0 {
    padding: 0px;
}

// Top
.paddingTop32 {
    padding-top: 32px;
}

.paddingTop30 {
    padding-top: 30px;
}

.paddingTop22 {
    padding-top: 22px;
}

.paddingTop20 {
    padding-top: 20px;
}

.paddingTop15 {
    padding-top: 15px;
}

.paddingTop14 {
    padding-top: 14px;
}

.paddingTop12 {
    padding-top: 12px;
}

.paddingTop10 {
    padding-top: 10px;
}

.paddingTop8 {
    padding-top: 8px;
}

.paddingTop6 {
    padding-top: 6px;
}

.paddingTop5 {
    padding-top: 5px;
}

.paddingTop4 {
    padding-top: 4px;
}

.paddingTop3 {
    padding-top: 3px;
}

.paddingTop2 {
    padding-top: 2px;
}

.paddingTop1 {
    padding-top: 1px;
}

.paddingTop05 {
    padding-top: 0.5px;
}

.paddingTop0 {
    padding-top: 0px;
}

// left
.paddingLeft32 {
    padding-left: 32px;
}

.paddingLeft30 {
    padding-left: 30px;
}

.paddingLeft22px {
    padding-left: 22px;
}

.paddingLeft20 {
    padding-left: 20px;
}

.paddingLeft15 {
    padding-left: 15px;
}

.paddingLeft14 {
    padding-left: 14px;
}

.paddingLeft12 {
    padding-left: 12px;
}

.paddingLeft10 {
    padding-left: 10px;
}

.paddingLeft8 {
    padding-left: 8px;
}

.paddingLeft6 {
    padding-left: 6px;
}

.paddingLeft5 {
    padding-left: 5px;
}

.paddingLeft4 {
    padding-left: 4px;
}

.paddingLeft3 {
    padding-left: 3px;
}

.paddingLeft2 {
    padding-left: 2px;
}

.paddingLeft1 {
    padding-left: 1px;
}

.paddingLeft05 {
    padding-left: 0.5px;
}

.paddingLeft0 {
    padding-left: 0px;
}

// right
.paddingRight32 {
    padding-right: 32px;
}

.paddingRight30 {
    padding-right: 30px;
}

.paddingRight22 {
    padding-right: 22px;
}

.paddingRight20 {
    padding-right: 20px;
}

.paddingRight15 {
    padding-right: 15px;
}

.paddingRight14 {
    padding-right: 14px;
}

.paddingRight12 {
    padding-right: 12px;
}

.paddingRight10 {
    padding-right: 10px;
}

.paddingRight8 {
    padding-right: 8px;
}

.paddingRight6 {
    padding-right: 6px;
}

.paddingRight5 {
    padding-right: 5px;
}

.paddingRight4 {
    padding-right: 4px;
}

.paddingRight3 {
    padding-right: 3px;
}

.paddingRight2 {
    padding-right: 2px;
}

.paddingRight1 {
    padding-right: 1px;
}

.paddingRight05 {
    padding-right: 0.5px;
}

.paddingRight0 {
    padding-right: 0px;
}

// bottom
.paddingBottom32 {
    padding-bottom: 32px;
}

.paddingBottom30 {
    padding-bottom: 30px;
}

.paddingBottom22 {
    padding-bottom: 22px;
}

.paddingBottom20 {
    padding-bottom: 20px;
}

.paddingBottom15 {
    padding-bottom: 15px;
}

.paddingBottom14 {
    padding-bottom: 14px;
}

.paddingBottom12 {
    padding-bottom: 12px;
}

.paddingBottom10 {
    padding-bottom: 10px;
}

.paddingBottom8 {
    padding-bottom: 8px;
}

.paddingBottom6 {
    padding-bottom: 6px;
}

.paddingBottom5 {
    padding-bottom: 5px;
}

.paddingBottom4 {
    padding-bottom: 4px;
}

.paddingBottom3 {
    padding-bottom: 3px;
}

.paddingBottom2 {
    padding-bottom: 2px;
}

.paddingBottom1 {
    padding-bottom: 1px;
}

.paddingBottom05 {
    padding-bottom: 0.5px;
}

.paddingBottom0 {
    padding-bottom: 0px;
}